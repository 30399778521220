<template>
  <div>

    <v-container class="pt-10 mt-10">
      <!-- <Sidebar /> -->
      <HeaderPage />
      <BackComponent :title_name="$t('message.label-address')" />

      <div v-if="isLoading" class="text-center" style="margin-top: 8%">
        <div v-for="i in 5" :key="i">
          <SkeletonProduct />
        </div>
      </div>

      <div v-else class=" mt-1">
        <!-- <v-radio-group > -->
        <v-row dense class="mt-4">
          <v-col v-for="(item, idx) in addressList" :key="idx" cols="12">
            <div>
              <v-card color="white">
                <label>
                  <div
                    class="d-flex flex-row"
                    @click="selectedAddress(item.id)"
                    style="cursor:pointer"
                  >
                    <v-avatar class="ma-3" size="100" tile style="float: left">
                      <v-icon size="100" color="#ff0090">
                        mdi-home-variant-outline
                      </v-icon>
                    </v-avatar>

                    <div style="margin-right: 10px">
                      <v-card-title
                        style="margin-left: -20px"
                        class="text-h6"
                        v-text="item.address_name"
                      ></v-card-title>
                      <span>
                        〒{{ formatPostalcode(item.postal_code) }} {{ item.prefecture }} {{ item.city }} {{ item.district }} {{ item.ward }}
                      </span>
                      <v-spacer></v-spacer>
                      <span>
                        {{ item.address }} {{ item.street_address }} {{ item.building }}
                      </span>
                    </div>
                    <div
                      class="flex-grow-1 d-flex flex-row-reverse align-self-start"
                      style="padding-right:10px;padding-top:10px"
                    >
                      <div v-if="item.is_checked">
                        <v-icon color="#ff0090">
                          mdi-check-circle
                        </v-icon>
                      </div>
                    </div>
                  </div>
                  <div>
                    <v-toolbar flat class="toolbar-locker-class">
                      <v-btn
                        disabled
                        style="background-color:transparent !important;min-width:0px"
                      >
                      </v-btn>
                      <v-btn
                        depressed
                        end
                        elevation="2000"
                        style="color: white;z-index: 2000;margin-left:-5%"
                        color="grey"
                        class="btn-delete black--text"
                        @click="deleteAddress(item.id)"
                      >
                        {{ $t("message.btn-delete") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        elevation="2000"
                        style="color: white; z-index: 200;"
                        color="grey"
                        class="black--text"
                        @click="$router.push('address/' + item.id)"

                      >
                        {{ $t("message.btn-editx") }}
                      </v-btn>
                    </v-toolbar>
                  </div>
                </label>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <!-- </v-radio-group> -->
        <div class="d-flex flex-row align-end justify-end mt-12" style="margin-bottom: 8rem">
          <router-link to="/address/add" style="text-decoration: none">
            <v-btn rounded color="#ff0090" style="color: white">
              <v-icon left>
                mdi-plus
              </v-icon>
              {{ $t("message.btn-add") }}
            </v-btn>
          </router-link>
        </div>
      </div>
    </v-container>
    <Sidebar />
  </div>
</template>

<script>
import { mdiArrowLeft } from "@mdi/js";
import Sidebar from "../components/developmentv2/Sidebar.vue";
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
import Back from "../components/Back.vue";
import LoadingPage from "../components/LoadingPage.vue";
import SkeletonProduct from "../components/skeleton/SkeletonProduct.vue";
export default {
  name: "AddressManagement",
  components: { Sidebar, BackComponent: Back, LoadingPage, SkeletonProduct, HeaderPage },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
      isLoading: false,
      message: {},
    };
  },
  created() {
    this.dispatchAddress();
  },
  methods: {
    formatPostalcode(postalcode) {
      let newPostcode = postalcode.slice(0, 3)+'-'+postalcode.slice(3)
      return newPostcode
    },
    async dispatchAddress() {
      this.isLoading = true;
      let data = await this.$store.dispatch("address_module/fetchAddress");
      this.isLoading = false;
      return data;
    },
    async deleteAddress(id) {
      this.message.title = this.$t("message.title-deleteForm-success-message");
      await this.$store.dispatch("address_module/deleteAddress", {
        id,
        message: {
          title: this.message.title,
        },
      });
    },
    selectedAddress(id) {
      this.$store.dispatch("address_module/selectedAddress", id);
      this.$store.dispatch("address_module/fetchAddress");
    },
  },
  computed: {
    addressList() {
      return this.$store.state.address_module.addressList;
    },
  },
};
</script>

<style>
.header-title {
  font-family: roboto;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: -15px;
  height: 25px;
}
</style>
